<template>
  <div class="app-box">
    <x-scroll-view :bottom="0.66">
      <x-form-box>
        <x-form-select label="设备类型" required :slots="EquipmentAllTypeEnum" @change="equipmentChange"></x-form-select>
        <x-form-input label="数量" type="number" required v-model="xhrData.count">
          <label class="from-unit">台</label>
        </x-form-input>
        <x-form-input label="预算单价" type="numberDot" required v-model="xhrData.price">
          <label class="from-unit">元</label>
        </x-form-input>
      </x-form-box>
    </x-scroll-view>
    <x-footer-box type="new" :isDisabledBtn="isDisabledBtn" @saveEvent="saveClick"></x-footer-box>
  </div>
</template>

<script>
import formBox from "@/components/formControl/formBox";
import formInput from "@/components/formControl/formInput";
import formSelect from "@/components/formControl/formSelect";
import footerBox from "@/components/footerBox";
import scrollView from "@/components/scrollView";

export default {
  data () {
    return {
      isDisabledBtn: true,
      xhrData: {
        typeId: null,
        typeName: null,
        count: null,
        price: null
      },
      EquipmentAllTypeEnum: [{
        flex: 1,
        values: [],
        textAlign: "center",
        defaultIndex: 0
      }]
    };
  },
  created () {
    this.setEnum();
  },
  methods: {
    equipmentChange (item) {
      this.xhrData.typeId = item.code;
      this.xhrData.typeName = item.name;
    },
    setEnum () {
      const configJson = JSON.parse(sessionStorage.getItem("configJson")).EquipmentAllTypeEnum;
      Object.keys(configJson).forEach(key => {
        this.EquipmentAllTypeEnum[0].values.push({
          code: key,
          name: configJson[key]
        });
      });
    },
    saveClick () {
      this.$store.commit("setSearchDeviceValue", this.xhrData);
      this.$router.back();
    },
    addressEvent () {
      this.$router.push({
        path: "/customerCenter/baseInfo/addressMap"
      });
    }
  },
  watch: {
    xhrData: {
      handler (newVal) {
        this.isDisabledBtn = false;
        Object.keys(newVal).forEach(key => {
          !newVal[key] && (this.isDisabledBtn = true);
        });
      },
      deep: true
    }
  },
  components: {
    [scrollView.name]: scrollView,
    [formInput.name]: formInput,
    [formBox.name]: formBox,
    [formSelect.name]: formSelect,
    [footerBox.name]: footerBox
  }
};
</script>

<style scoped>
.pos-address-icon {
  margin-left: 0.1rem;
  width: 0.14rem;
  height: 0.18rem;
  background: url('~@images/pos_address_icon.png') no-repeat center/0.14rem
    0.18rem;
}
</style>
